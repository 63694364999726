import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Translate } from 'react-jhipster';
import { useSnackbar } from 'notistack';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { deleteEntity } from './advertising.reducer';

export const AdvertisingDeleteDialog = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const updating = useAppSelector(state => state.advertising.updating);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDelete = async () => {
    const result = await dispatch(deleteEntity(id));
    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(<Translate contentKey="kafaatApp.advertising.deleted">Advertisement deleted successfully</Translate>, {
        variant: 'success',
      });
      handleClose();
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Translate contentKey="kafaatApp.advertising.delete.question">Are you sure you want to delete this Advertisement?</Translate>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <LoadingButton onClick={confirmDelete} loading={updating} variant="contained" color="error">
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AdvertisingDeleteDialog;
