export interface ICateringRequest {
  id?: number;
  date?: string;
  time?: string;
  city?: string;
  occasion?: string;
  numberOfPeople?: number;
  details?: string;
  phoneNumber?: string;
  email?: string;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export const defaultValue: Readonly<ICateringRequest> = {};
