export interface IStorage {
  id?: number;
  name?: string;
  path?: string;
  contentType?: string;
  size?: number;
}

export interface IAdvertising {
  id?: number;
  image?: IStorage;
  imageId?: number;
  startDate?: string;
  endDate?: string;
  businessId?: number;
  businessName?: string;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
}

export const defaultValue: Readonly<IAdvertising> = {};
