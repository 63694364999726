import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity } from './advertising.reducer';
import { getEntities as getBusinesses } from 'app/entities/business/business.reducer';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Button,
  TextField,
  Autocomplete,
  Box,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { CloudUpload } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { IAdvertising } from 'app/shared/model/advertising.model';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const AdvertisingUpdate = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState<string>('');
  const businesses = useAppSelector(state => state.business.entities);

  const handleClose = () => {
    setOpen(false);
    navigate('/advertising' + location.search);
  };

  useEffect(() => {
    if (open) {
      dispatch(getBusinesses({}));
    }
  }, [open]);

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setFieldValue('image', file);
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setImagePreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const saveEntity = async values => {
    try {
      const formData = new FormData();

      // Append image first
      formData.append('image', values.image);

      // Append data
      formData.append(
        'data',
        new Blob(
          [
            JSON.stringify({
              businessId: values.businessId,
              startDate: dayjs(values.startDate).format('YYYY-MM-DD'),
              endDate: dayjs(values.endDate).format('YYYY-MM-DD'),
            }),
          ],
          {
            type: 'application/json',
          }
        )
      );

      const result = await dispatch(createEntity(formData));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.advertising.created'), { variant: 'success' });
        handleClose();
        return true;
      }
      enqueueSnackbar(translate('kafaatApp.advertising.error'), { variant: 'error' });
      return false;
    } catch (error) {
      console.error('Error saving entity:', error);
      enqueueSnackbar(translate('kafaatApp.advertising.error'), { variant: 'error' });
      return false;
    }
  };

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required(translate('error.form.required')),
    endDate: Yup.date().required(translate('error.form.required')).min(Yup.ref('startDate'), 'End date must be after start date'),
    businessId: Yup.number().required(translate('error.form.required')),
  });

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose}>
      <DialogTitle color={'secondary'}>
        <Translate contentKey="kafaatApp.advertising.home.createLabel">Create new Advertising</Translate>
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={{
          image: null,
          startDate: dayjs(),
          endDate: dayjs().add(1, 'month'),
          businessId: '',
        }}
        validationSchema={validationSchema}
        onSubmit={saveEntity}
      >
        {({ errors, handleBlur, handleSubmit, isSubmitting, setFieldValue, setFieldTouched, touched, values }) => (
          <form
            noValidate
            onSubmit={e => {
              console.log('Form onSubmit triggered'); // Debug log
              handleSubmit(e);
            }}
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" error={Boolean(touched.image && errors.image)}>
                    <FormLabel>
                      <Translate contentKey="kafaatApp.advertising.image">Image</Translate>
                    </FormLabel>
                    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                      <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUpload />}
                        color={touched.image && errors.image ? 'error' : 'primary'}
                      >
                        Upload Image
                        <VisuallyHiddenInput
                          type="file"
                          accept="image/*"
                          onChange={e => handleImageChange(e, setFieldValue)}
                          onBlur={() => setFieldTouched('image', true)}
                          name="image"
                        />
                      </Button>
                      {imagePreview && (
                        <Box
                          component="img"
                          sx={{
                            height: 200,
                            width: 'auto',
                            maxWidth: '100%',
                            objectFit: 'contain',
                          }}
                          src={imagePreview}
                          alt="Preview"
                        />
                      )}
                      {touched.image && errors.image && <FormHelperText error>{errors.image?.toString()}</FormHelperText>}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.startDate && errors.startDate)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.advertising.startDate">Start Date</Translate>
                      </FormLabel>
                      <DatePicker
                        value={values.startDate}
                        onChange={date => setFieldValue('startDate', date)}
                        renderInput={props => (
                          <TextField
                            {...props}
                            variant="filled"
                            error={Boolean(touched.startDate && errors.startDate)}
                            helperText={touched.startDate && errors.startDate ? errors.startDate.toString() : ''}
                          />
                        )}
                      />
                    </FormControl>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.endDate && errors.endDate)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.advertising.endDate">End Date</Translate>
                      </FormLabel>
                      <DatePicker
                        value={values.endDate}
                        onChange={date => setFieldValue('endDate', date)}
                        renderInput={props => (
                          <TextField
                            {...props}
                            variant="filled"
                            error={Boolean(touched.endDate && errors.endDate)}
                            helperText={touched.endDate && errors.endDate ? errors.endDate.toString() : ''}
                          />
                        )}
                      />
                    </FormControl>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" error={Boolean(touched.businessId && errors.businessId)}>
                    <FormLabel>
                      <Translate contentKey="kafaatApp.advertising.business">Business</Translate>
                    </FormLabel>
                    <Autocomplete
                      options={businesses}
                      getOptionLabel={option => option.name}
                      value={businesses.find(b => b.id === values.businessId) || null}
                      onChange={(event, newValue) => {
                        setFieldValue('businessId', newValue?.id || '');
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="filled"
                          error={Boolean(touched.businessId && errors.businessId)}
                          helperText={touched.businessId && errors.businessId ? errors.businessId.toString() : ''}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <LoadingButton size={'large'} color="primary" variant="contained" loading={isSubmitting} type={'submit'}>
                <Translate contentKey="entity.action.save">Save</Translate>
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AdvertisingUpdate;
