import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, getEntitiesPageable } from './advertising.reducer';
import AddIcon from '@mui/icons-material/Add';
import { DeleteIcon, EditIcon, DownloadQRIcon } from 'app/components/icons';
import { getSortState, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import AdvertisingUpdate from './advertising-update';
import AdvertisingDeleteDialog from './advertising-delete-dialog';

export const Advertising = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(undefined);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, rowsPerPage, 'id'), location.search)
  );

  const advertisingList = useAppSelector(state => state.advertising?.entities || []);
  const loading = useAppSelector(state => state.advertising.loading);
  const updateSuccess = useAppSelector(state => state.advertising.updateSuccess);
  const totalItems = useAppSelector(state => state.advertising.totalItems);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationState({
      ...paginationState,
      activePage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginationState({
      ...paginationState,
      itemsPerPage: parseInt(event.target.value, 10),
      activePage: 0,
    });
  };

  const getAllEntities = () => {
    dispatch(
      getEntitiesPageable({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.itemsPerPage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    if (updateSuccess) {
      getAllEntities();
    }
  }, [updateSuccess]);

  useEffect(() => {
    console.log('Advertising List:', advertisingList);
  }, [advertisingList]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalItems) : 0;

  return (
    <Container maxWidth={false}>
      <Box sx={{ py: 3 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom>
              <Translate contentKey="kafaatApp.advertising.home.title">Advertising</Translate>
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => setUpdateOpen(true)} startIcon={<AddIcon />}>
              <Translate contentKey="kafaatApp.advertising.home.createLabel">Create new Advertising</Translate>
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Translate contentKey="kafaatApp.advertising.image">Image</Translate>
                </TableCell>
                <TableCell>
                  <Translate contentKey="kafaatApp.advertising.startDate">Start Date</Translate>
                </TableCell>
                <TableCell>
                  <Translate contentKey="kafaatApp.advertising.endDate">End Date</Translate>
                </TableCell>
                <TableCell>
                  <Translate contentKey="kafaatApp.advertising.business">Business</Translate>
                </TableCell>
                <TableCell>
                  <Translate contentKey="kafaatApp.advertising.actions">Actions</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : advertisingList?.length > 0 ? (
                <>
                  {advertisingList.map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{row.image && <img src={row.image.filePath} height={50} width={50} />}</TableCell>

                      <TableCell>{row.startDate}</TableCell>
                      <TableCell>{row.endDate}</TableCell>
                      <TableCell>{row.businessName}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title={<Translate contentKey="entity.action.download">Download</Translate>}>
                            <IconButton component="a" href={row.image?.filePath} download disabled={!row.image?.id}>
                              <DownloadQRIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                            <IconButton
                              onClick={() => {
                                setDeleteId(row.id);
                                setDeleteOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {totalItems ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={translate('home.rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to}`}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </TableContainer>
      </Box>
      <AdvertisingUpdate open={updateOpen} setOpen={setUpdateOpen} />
      <AdvertisingDeleteDialog open={deleteOpen} setOpen={setDeleteOpen} id={deleteId} />
    </Container>
  );
};

export default Advertising;
