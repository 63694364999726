import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICateringRequest, defaultValue } from 'app/shared/model/catering-request';

const initialState: EntityState<ICateringRequest> = {
  loading: false,
  errorMessage: null,
  entities: [] as Array<ICateringRequest>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/admin/catering-requests';

// Actions

export const getEntities = createAsyncThunk('cateringRequest/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}`;
  return axios.get<ICateringRequest[]>(requestUrl);
});

export const getEntitiesPageable = createAsyncThunk(
  'cateringRequest/fetch_entity_list_pageable',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    return axios.get<ICateringRequest[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'cateringRequest/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICateringRequest>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'cateringRequest/create_entity',
  async (entity: ICateringRequest, thunkAPI) => {
    const result = await axios.post<ICateringRequest>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesPageable({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'cateringRequest/update_entity',
  async (entity: ICateringRequest, thunkAPI) => {
    const result = await axios.put<ICateringRequest>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesPageable({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'cateringRequest/delete_entity',
  async (entity: any, thunkAPI) => {
    const config = {
      headers: { 'Accept-Language': entity.locale },
    };
    const requestUrl = `${apiUrl}/${entity.id}`;
    const result = await axios.delete<ICateringRequest>(requestUrl, config);
    thunkAPI.dispatch(getEntitiesPageable({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const CateringRequestSlice = createEntitySlice({
  name: 'cateringRequest',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesPageable), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: Array.isArray(data) ? data : [],
          totalItems: parseInt(headers['x-total-count'] || '0', 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntitiesPageable), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected(getEntities, getEntity, getEntitiesPageable, createEntity, updateEntity, deleteEntity), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = CateringRequestSlice.actions;

// Reducer
export default CateringRequestSlice.reducer;
