import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity } from './catering-request.reducer';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Button,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

export const CateringRequestUpdate = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const isNew = id === undefined;
  const cateringRequestEntity = useAppSelector(state => state.cateringRequest.entity);
  const loading = useAppSelector(state => state.cateringRequest.loading);
  const updateSuccess = useAppSelector(state => state.cateringRequest.updateSuccess);

  const handleClose = () => {
    setOpen(false);
    navigate('/catering-requests' + location.search);
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, [open]);

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    const entity = {
      ...values,
      date: values.date.format('YYYY-MM-DD'),
      time: values.time.format('HH:mm:ss'),
    };

    if (isNew) {
      const {
        meta: { requestStatus },
      } = await dispatch(createEntity(entity));
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.cateringRequest.created'), { variant: 'success' });
      }
    } else {
      const {
        meta: { requestStatus },
      } = await dispatch(updateEntity(entity));
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.cateringRequest.updated'), { variant: 'success' });
      }
      return requestStatus;
    }
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" color={'secondary'}>
        {isNew ? (
          <Translate contentKey="kafaatApp.cateringRequest.home.createLabel">Create new catering request</Translate>
        ) : (
          <Translate contentKey="kafaatApp.cateringRequest.home.editLabel">Edit catering request</Translate>
        )}
      </DialogTitle>
      <Divider />
      {(isNew || (!isNew && !loading)) && (
        <Formik
          initialValues={
            isNew
              ? {
                  date: dayjs(),
                  time: dayjs(),
                  city: '',
                  occasion: '',
                  numberOfPeople: 1,
                  details: '',
                  phoneNumber: '',
                  email: '',
                }
              : {
                  ...cateringRequestEntity,
                  date: dayjs(cateringRequestEntity.date),
                  time: dayjs(`2000-01-01T${cateringRequestEntity.time}`),
                }
          }
          validationSchema={Yup.object().shape({
            date: Yup.mixed().required(translate('error.form.required')),
            time: Yup.mixed().required(translate('error.form.required')),
            city: Yup.string().required(translate('error.form.required')),
            occasion: Yup.string().required(translate('error.form.required')),
            numberOfPeople: Yup.number()
              .min(1, translate('error.form.min', { min: 1 }))
              .required(translate('error.form.required')),
            phoneNumber: Yup.string().required(translate('error.form.required')),
            email: Yup.string().email(translate('error.form.email')).required(translate('error.form.required')),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const requestStatus = await saveEntity(values);
              if (scriptedRef.current && requestStatus === 'fulfilled') {
                setStatus({ success: true });
                setSubmitting(false);
                handleClose();
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth margin="normal" error={Boolean(touched.date && errors.date)}>
                        <FormLabel>
                          <Translate contentKey="kafaatApp.cateringRequest.date">Date</Translate>
                        </FormLabel>
                        <DatePicker
                          value={values.date}
                          onChange={date => setFieldValue('date', date)}
                          renderInput={props => (
                            <TextField
                              {...props}
                              variant="filled"
                              error={Boolean(touched.date && errors.date)}
                              helperText={touched.date && errors.date ? errors.date.toString() : ''}
                            />
                          )}
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth margin="normal" error={Boolean(touched.time && errors.time)}>
                        <FormLabel>
                          <Translate contentKey="kafaatApp.cateringRequest.time">Time</Translate>
                        </FormLabel>
                        <TimePicker
                          value={values.time}
                          onChange={time => setFieldValue('time', time)}
                          renderInput={props => (
                            <TextField
                              {...props}
                              variant="filled"
                              error={Boolean(touched.time && errors.time)}
                              helperText={touched.time && errors.time ? errors.time.toString() : ''}
                            />
                          )}
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.city && errors.city)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.cateringRequest.city">City</Translate>
                      </FormLabel>
                      <FilledInput name="city" onBlur={handleBlur} onChange={handleChange} value={values.city} />
                      {touched.city && errors.city && <FormHelperText error>{errors.city.toString()}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.occasion && errors.occasion)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.cateringRequest.occasion">Occasion</Translate>
                      </FormLabel>
                      <FilledInput name="occasion" onBlur={handleBlur} onChange={handleChange} value={values.occasion} />
                      {touched.occasion && errors.occasion && <FormHelperText error>{errors.occasion.toString()}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.numberOfPeople && errors.numberOfPeople)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.cateringRequest.numberOfPeople">Number of People</Translate>
                      </FormLabel>
                      <FilledInput
                        type="number"
                        name="numberOfPeople"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.numberOfPeople}
                      />
                      {touched.numberOfPeople && errors.numberOfPeople && (
                        <FormHelperText error>{errors.numberOfPeople.toString()}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.details && errors.details)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.cateringRequest.details">Details</Translate>
                      </FormLabel>
                      <FilledInput multiline rows={3} name="details" onBlur={handleBlur} onChange={handleChange} value={values.details} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.phoneNumber && errors.phoneNumber)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.cateringRequest.phoneNumber">Phone Number</Translate>
                      </FormLabel>
                      <FilledInput name="phoneNumber" onBlur={handleBlur} onChange={handleChange} value={values.phoneNumber} />
                      {touched.phoneNumber && errors.phoneNumber && <FormHelperText error>{errors.phoneNumber.toString()}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.email && errors.email)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.cateringRequest.email">Email</Translate>
                      </FormLabel>
                      <FilledInput name="email" onBlur={handleBlur} onChange={handleChange} value={values.email} />
                      {touched.email && errors.email && <FormHelperText error>{errors.email.toString()}</FormHelperText>}
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                  <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                </Button>
                <LoadingButton size={'large'} color="primary" variant="contained" loading={isSubmitting} type={'submit'}>
                  <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default CateringRequestUpdate;
