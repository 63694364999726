import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ICateringRequest } from 'app/shared/model/catering-request';
import { getEntities, getEntitiesPageable } from './catering-request.reducer';

import AddIcon from '@mui/icons-material/Add';
import { DeleteIcon, EditIcon } from 'app/components/icons';
import { getSortState, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { SORT } from 'app/shared/util/pagination.constants';
import CateringRequestUpdate from './catering-request-update';
import CateringRequestDeleteDialog from './catering-request-delete-dialog';

export const CateringRequest = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [updateId, setUpdateId] = React.useState(undefined);
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(undefined);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, rowsPerPage, 'id'), location.search)
  );

  const cateringRequestList = useAppSelector(state => state.cateringRequest?.entities || []);
  const loading = useAppSelector(state => state.cateringRequest.loading);
  const updateSuccess = useAppSelector(state => state.cateringRequest.updateSuccess);
  const totalItems = useAppSelector(state => state.cateringRequest.totalItems);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationState({
      ...paginationState,
      activePage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginationState({
      ...paginationState,
      itemsPerPage: parseInt(event.target.value, 10),
      activePage: 0,
    });
  };

  const getAllEntities = () => {
    dispatch(
      getEntitiesPageable({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [updateSuccess]);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, paginationState.itemsPerPage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramsPage = params.get('page');
    const sort = params.get(SORT);
    if (paramsPage && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: Number(paramsPage),
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - cateringRequestList.length) : 0;

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Grid container justifyContent={'flex-end'} sx={{ mb: 2 }}>
          <Button
            color="primary"
            size="large"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setUpdateId(undefined);
              setUpdateOpen(true);
            }}
          >
            <Translate contentKey="kafaatApp.cateringRequest.home.createLabel">Create new catering request</Translate>
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }}>
                  <Translate contentKey="kafaatApp.cateringRequest.date">Date</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }}>
                  <Translate contentKey="kafaatApp.cateringRequest.time">Time</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }}>
                  <Translate contentKey="kafaatApp.cateringRequest.city">City</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }}>
                  <Translate contentKey="kafaatApp.cateringRequest.occasion">Occasion</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }}>
                  <Translate contentKey="kafaatApp.cateringRequest.numberOfPeople">Number of People</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }}>
                  <Translate contentKey="kafaatApp.cateringRequest.phoneNumber">Phone Number</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }}>
                  <Translate contentKey="kafaatApp.cateringRequest.email">Email</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }}>
                  <Translate contentKey="kafaatApp.cateringRequest.actions">Actions</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={8} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : Array.isArray(cateringRequestList) && cateringRequestList.length > 0 ? (
                <>
                  {cateringRequestList.map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.time}</TableCell>
                      <TableCell>{row.city}</TableCell>
                      <TableCell>{row.occasion}</TableCell>
                      <TableCell>{row.numberOfPeople}</TableCell>
                      <TableCell>{row.phoneNumber}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setUpdateId(row.id);
                                setUpdateOpen(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setDeleteId(row.id);
                                setDeleteOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 2 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {totalItems ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={translate('home.rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to}`}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </TableContainer>
      </Box>
      <CateringRequestUpdate open={updateOpen} setOpen={setUpdateOpen} id={updateId} />
      <CateringRequestDeleteDialog open={deleteOpen} setOpen={setDeleteOpen} id={deleteId} />
    </Container>
  );
};

export default CateringRequest;
