import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { updateEntity, createEntity, reset } from './offer.reducer';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Autocomplete,
  Switch,
} from '@mui/material';
import { Formik } from 'formik';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as Yup from 'yup';
import { PHONE_REGEX } from 'app/config/constants';
import { AttachmentIcon } from 'app/components/icons';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { getAllEntities as getAllCompanies } from 'app/entities/company/company.reducer';

export const OfferUpdate = ({ open, setOpen, id, businessId, offerEntity }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const [showCodeField, setShowCodeField] = React.useState(undefined);

  const navigate = useNavigate();

  const isNew = id === undefined;

  const { enqueueSnackbar } = useSnackbar();

  const loading = useAppSelector(state => state.offer.loading);
  const updating = useAppSelector(state => state.offer.updating);
  const updateSuccess = useAppSelector(state => state.offer.updateSuccess);

  const [companies, setCompanies] = useState([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);

  const [initialCompanies, setInitialCompanies] = useState([]);

  useEffect(() => {
    dispatch(getAllCompanies())
      .unwrap()
      .then(response => {
        setCompanies(response.data || []);
        setCompaniesLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (!isNew && companiesLoaded && offerEntity?.exclusiveCompanyIds?.length > 0) {
      const selectedCompanies = companies.filter(company => offerEntity.exclusiveCompanyIds.includes(company.id));
      console.log('Found selected companies:', selectedCompanies);
      setInitialCompanies(selectedCompanies);
    }
  }, [companiesLoaded, companies, offerEntity]);

  const handleClose = () => {
    setOpen(false);
    navigate('/business/' + businessId);
  };

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (offerEntity) {
      setShowCodeField(!!offerEntity.code);
    }
  }, [offerEntity]);

  const saveEntity = async values => {
    console.log('Saving with values:', values);
    const entity = {
      ...values,
      isExclusive: values.isExclusive,
      exclusiveCompanyIds: values.isExclusive ? values.exclusiveCompanies.map(c => c.id) : [],
    };
    console.log('Transformed entity:', entity);
    const formData: any = new FormData();
    if (entity.title) formData.append('title', entity.title);
    if (entity.titleEn) formData.append('titleEn', entity.titleEn);
    if (!entity.image?.filePath && entity.image) formData.append('image', entity.image);
    if (!entity.agreement?.filePath && entity.agreement) formData.append('agreement', entity.agreement);
    if (entity.businessId) formData.append('businessId', entity.businessId);
    // if (entity.type) formData.append('type', type);
    if (!entity.details?.filePath && entity.details) formData.append('details', entity.details);
    if (entity.expirationDate) formData.append('expirationDate', moment(entity.expirationDate)?.format('YYYY-MM-DD'));
    if (entity.description) formData.append('description', entity.description);
    if (entity.descriptionEn) formData.append('descriptionEn', entity.descriptionEn);
    if (!isNew && entity.contactId) formData.append('contact.id', entity.contactId);
    if (entity.cFirstName) formData.append('contact.firstName', entity.cFirstName);
    if (entity.cLastName) formData.append('contact.lastName', entity.cLastName);
    if (entity.cEmail) formData.append('contact.email', entity.cEmail);
    if (entity.cPhoneNumber) formData.append('contact.phoneNumber', entity.cPhoneNumber);

    if (showCodeField) formData.append('code', entity.code);

    formData.append('isExclusive', entity.isExclusive);
    if (entity.isExclusive && entity.exclusiveCompanyIds.length > 0) {
      entity.exclusiveCompanyIds.forEach(id => {
        formData.append('exclusiveCompanyIds', id);
      });
    }

    if (isNew) {
      const {
        meta: { requestStatus },
      } = await dispatch(createEntity(formData));
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.business.offer.createSuccess'), { variant: 'success' });
      }
    } else {
      const {
        meta: { requestStatus },
      } = await dispatch(
        updateEntity({
          id: offerEntity.id,
          values: formData,
        })
      );
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.business.offer.updateSuccess'), { variant: 'success' });
      }
    }
  };

  const formikRef = React.useRef(null);

  const getInitialValues = () => {
    if (isNew) {
      return {
        businessId,
        // type,
        title: undefined,
        titleEn: undefined,
        description: undefined,
        descriptionEn: undefined,
        percentage: undefined,
        image: undefined,
        agreement: undefined,
        details: undefined,
        expirationDate: null,
        useCode: undefined,
        code: undefined,
        cFirstName: undefined,
        cLastName: undefined,
        cPhoneNumber: undefined,
        cEmail: undefined,
        isExclusive: false,
        exclusiveCompanies: [],
      };
    }

    return {
      ...offerEntity,
      expirationDate: moment(offerEntity.expirationDate),
      businessId: offerEntity.businessId,
      cFirstName: offerEntity.contact?.firstName,
      cLastName: offerEntity.contact?.lastName,
      cPhoneNumber: offerEntity.contact?.phoneNumber,
      cEmail: offerEntity.contact?.email,
      useCode: !!offerEntity.code,
      code: offerEntity.code,
      contactId: offerEntity.contact?.id,
      isExclusive: offerEntity.isExclusive || false,
      exclusiveCompanies: initialCompanies,
    };
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" color={'secondary'}>
        {isNew ? (
          <Translate contentKey="kafaatApp.business.offer.createOffer">Create new Entity</Translate>
        ) : (
          <Translate contentKey="kafaatApp.business.offer.editOffer">Edit Entity</Translate>
        )}
      </DialogTitle>
      <Divider />
      {(isNew || (!isNew && !loading)) && (
        <Formik
          innerRef={formikRef}
          initialValues={getInitialValues()}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required(translate('error.form.required')),
            titleEn: Yup.string().max(255).required(translate('error.form.required')),
            description: Yup.string().required(translate('error.form.required')),
            descriptionEn: Yup.string().required(translate('error.form.required')),
            expirationDate: Yup.string().nullable().required(translate('error.form.required')),
            image: Yup.mixed().nullable().required(translate('error.form.required')),
            details: Yup.mixed().nullable(),
            agreement: Yup.mixed().nullable().required(translate('error.form.required')),
            cFirstName: Yup.string().max(255),
            cLastName: Yup.string().max(255),
            cPhoneNumber: Yup.string().trim().matches(PHONE_REGEX, translate('error.form.invalidPhone')),
            cEmail: Yup.string().email(translate('error.form.invalidEmail')),
            useCode: Yup.boolean(),
            code: Yup.string().nullable(),
            isExclusive: Yup.boolean(),
            exclusiveCompanies: Yup.array().when('isExclusive', {
              is: true,
              then: Yup.array().min(1, translate('error.form.required')).required(translate('error.form.required')),
            }),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await saveEntity(values);

              if (scriptedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                handleClose();
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
          enableReinitialize={true}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Typography color="secondary" variant="subtitle1" gutterBottom>
                  {<Translate contentKey="kafaatApp.business.offer.offerDetails"> Details </Translate>}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.title && errors.title)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.titleAr">Title </Translate>
                      </FormLabel>
                      <FilledInput
                        id="offer-title"
                        type={'text'}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.title}
                      />
                      {touched.title && errors.title && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.title.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.titleEn && errors.titleEn)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.titleEn">title_en </Translate>
                      </FormLabel>
                      <FilledInput type={'text'} name="titleEn" onBlur={handleBlur} onChange={handleChange} value={values.titleEn} />
                      {touched.titleEn && errors.titleEn && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.titleEn.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl size="small" margin="normal" error={Boolean(touched.useCode && errors.useCode)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.codeOrQR">Redeem by QR or Code </Translate>
                      </FormLabel>
                      <RadioGroup row name={'useCode'} value={values.useCode} onBlur={handleBlur} onChange={handleChange}>
                        <FormControlLabel
                          value={true}
                          control={<Radio onClick={() => setShowCodeField(true)} size="small" />}
                          label={
                            <Typography fontSize={'small'}>
                              <Translate contentKey="kafaatApp.business.offer.CodeTrue">Use Code </Translate>
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio onClick={() => setShowCodeField(false)} size="small" />}
                          label={
                            <Typography fontSize={'small'}>
                              <Translate contentKey="kafaatApp.business.offer.CodeFalse">Generate QR </Translate>
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      {touched.useCode && errors.useCode && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.useCode.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.image && errors.image)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.image">image </Translate>
                      </FormLabel>
                      <FilledInput
                        id="file-name"
                        type={'text'}
                        value={values.image ? (values.image.name ? values.image.name : values.image.fileName) : ''}
                        endAdornment={
                          <IconButton component="label">
                            <AttachmentIcon />
                            <input
                              type="file"
                              accept={'image/*'}
                              hidden
                              name={'image'}
                              onChange={event => {
                                setFieldValue('image', event.currentTarget.files[0]);
                              }}
                              onBlur={handleBlur}
                              id="fileupload"
                            />
                          </IconButton>
                        }
                      />
                      {touched.image && errors.image && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.image.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {showCodeField && (
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal" error={Boolean(touched.code && errors.code)}>
                        <FormLabel>
                          <Translate contentKey="kafaatApp.business.offer.code">Code </Translate>
                        </FormLabel>
                        <FilledInput
                          id="offer-code"
                          type={'text'}
                          name="code"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.code}
                        />
                        {touched.code && errors.code && (
                          <FormHelperText error id="standard-weight-helper-text">
                            {errors.code.toString()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <FormControl fullWidth margin="normal" error={Boolean(touched.description && errors.description)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.business.offer.descriptionAr">Description </Translate>
                  </FormLabel>
                  <FilledInput
                    multiline
                    rows={3}
                    id="offer-description"
                    type={'text'}
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                  />
                  {touched.description && errors.description && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.description.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(touched.descriptionEn && errors.descriptionEn)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.business.offer.descriptionEn">description_en </Translate>
                  </FormLabel>
                  <FilledInput
                    multiline
                    rows={3}
                    id="offer-description_en"
                    type={'text'}
                    name="descriptionEn"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.descriptionEn}
                  />
                  {touched.descriptionEn && errors.descriptionEn && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.descriptionEn.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.expirationDate && errors.expirationDate)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.expiryDate">Expiry Date </Translate>
                      </FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={values.expirationDate}
                          inputFormat={'YYYY-MM-DD'}
                          onChange={event => {
                            setFieldValue('expirationDate', event.$d);
                            return event.$d;
                          }}
                          renderInput={params => <TextField {...params} variant={'filled'} value={values.expirationDate} />}
                        />
                      </LocalizationProvider>
                      {touched.expirationDate && errors.expirationDate && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.expirationDate.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.agreement && errors.agreement)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.agreement">Agreement </Translate>
                      </FormLabel>
                      <FilledInput
                        id="file-name"
                        type={'text'}
                        value={values.agreement ? (values.agreement.name ? values.agreement.name : values.agreement.fileName) : ''}
                        endAdornment={
                          <IconButton component="label">
                            <AttachmentIcon />
                            <input
                              type="file"
                              accept={'application/pdf'}
                              hidden
                              name={'agreement'}
                              onChange={event => {
                                setFieldValue('agreement', event.currentTarget.files[0]);
                              }}
                              onBlur={handleBlur}
                              id="fileupload"
                            />
                          </IconButton>
                        }
                      />
                      {touched.agreement && errors.agreement && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.agreement.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.details && errors.details)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.attachDetails">Details </Translate>
                      </FormLabel>
                      <FilledInput
                        id="file-name"
                        type={'text'}
                        value={values.details ? (values.details.name ? values.details.name : values.details.fileName) : ''}
                        endAdornment={
                          <IconButton component="label">
                            <AttachmentIcon />
                            <input
                              type="file"
                              accept={'application/pdf'}
                              hidden
                              name={'details'}
                              onChange={event => {
                                setFieldValue('details', event.currentTarget.files[0]);
                              }}
                              onBlur={handleBlur}
                              id="fileupload"
                            />
                          </IconButton>
                        }
                      />
                      {touched.details && errors.details && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.details.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography color="secondary" variant="subtitle1" gutterBottom>
                  <Translate contentKey="kafaatApp.business.offer.contactPerson">Contact Person </Translate>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.cFirstName && errors.cFirstName)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.firstName">First Name </Translate>
                      </FormLabel>
                      <FilledInput
                        id="offer-firstName"
                        type={'text'}
                        name="cFirstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cFirstName}
                      />
                      {touched.cFirstName && errors.cFirstName && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.cFirstName.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.cLastName && errors.cLastName)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.lastName">Last Name </Translate>
                      </FormLabel>
                      <FilledInput
                        id="offer-lastName"
                        type={'text'}
                        name="cLastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cLastName}
                      />
                      {touched.cLastName && errors.cLastName && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.cLastName.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.cPhoneNumber && errors.cPhoneNumber)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.employee.phoneNumber">phoneNumber </Translate>
                      </FormLabel>
                      <FilledInput
                        id="offer-phoneNumber"
                        type={'text'}
                        name="cPhoneNumber"
                        value={values.cPhoneNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.cPhoneNumber && errors.cPhoneNumber && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.cPhoneNumber.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.cEmail && errors.cEmail)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.employee.email">Email </Translate>
                      </FormLabel>
                      <FilledInput
                        id="offer-email"
                        type={'text'}
                        name="cEmail"
                        value={values.cEmail}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.cEmail && errors.cEmail && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.cEmail.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isExclusive}
                            onChange={e => {
                              console.log('Switch changed:', e.target.checked);
                              setFieldValue('isExclusive', e.target.checked);
                              if (!e.target.checked) {
                                setFieldValue('exclusiveCompanies', []);
                              }
                            }}
                            name="isExclusive"
                          />
                        }
                        label={<Translate contentKey="kafaatApp.business.offer.isExclusive">Exclusive Offer</Translate>}
                      />
                    </FormControl>
                  </Grid>

                  {values.isExclusive && (
                    <Grid item xs={12}>
                      <FormControl fullWidth margin="normal">
                        <FormLabel>
                          <Translate contentKey="kafaatApp.business.offer.exclusiveCompanies">Exclusive Companies</Translate>
                        </FormLabel>
                        <Autocomplete
                          multiple
                          options={companies}
                          getOptionLabel={option => option.name}
                          value={values.exclusiveCompanies}
                          onChange={(_, newValue) => {
                            console.log('Autocomplete changed:', newValue);
                            setFieldValue('exclusiveCompanies', newValue);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="filled"
                              error={Boolean(touched.exclusiveCompanies && errors.exclusiveCompanies)}
                              helperText={
                                touched.exclusiveCompanies && errors.exclusiveCompanies ? errors.exclusiveCompanies.toString() : undefined
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                  <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                </Button>
                <LoadingButton size={'large'} color="primary" variant="contained" loading={isSubmitting} type={'submit'}>
                  <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default OfferUpdate;
