import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Advertising from 'app/entities/advertising/advertising';

const AdvertisingRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Advertising />} />
  </ErrorBoundaryRoutes>
);

export default AdvertisingRoutes;
