import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IAdvertising, defaultValue } from 'app/shared/model/advertising.model';

const initialState: EntityState<IAdvertising> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/admin/advertising';

// Actions
export const getEntities = createAsyncThunk('advertising/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}`;
  return axios.get<IAdvertising[]>(requestUrl);
});

export const getEntitiesPageable = createAsyncThunk(
  'advertising/fetch_entity_list_pageable',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    return axios.get<IAdvertising[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'advertising/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAdvertising>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'advertising/create_entity',
  async (entity: FormData, thunkAPI) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const result = await axios.post<IAdvertising>(apiUrl, entity, config);
    thunkAPI.dispatch(getEntitiesPageable({ page: 0, size: 20, sort: 'id,desc' }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'advertising/update_entity',
  async ({ id, values }: { id: string | number; values: FormData }, thunkAPI) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const result = await axios.put<IAdvertising>(`${apiUrl}/${id}`, values, config);
    thunkAPI.dispatch(getEntitiesPageable({ page: 0, size: 20, sort: 'id,desc' }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'advertising/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.delete<IAdvertising>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice
export const AdvertisingSlice = createEntitySlice({
  name: 'advertising',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesPageable), (state, action) => {
        const { data, headers } = action.payload;
        console.log('Received advertising data:', data);
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected(getEntities, getEntity, createEntity, updateEntity, deleteEntity), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = AdvertisingSlice.actions;

export default AdvertisingSlice.reducer;
