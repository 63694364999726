import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity } from './catering-request.reducer';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DangerIcon } from 'app/components/icons';
import { useSnackbar } from 'notistack';

export const CateringRequestDeleteDialog = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const updateSuccess = useAppSelector(state => state.cateringRequest.updateSuccess);
  const loading = useAppSelector(state => state.cateringRequest.loading);
  const error = useAppSelector(state => state.cateringRequest.error);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/catering-requests' + location.search);
    setOpen(false);
  };

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  const confirmDelete = async () => {
    const {
      meta: { requestStatus },
    } = await dispatch(deleteEntity({ id: id, locale: currentLocale }));
    if (requestStatus === 'fulfilled') enqueueSnackbar(translate('kafaatApp.cateringRequest.deleted'), { variant: 'success' });
    else enqueueSnackbar(translate('kafaatApp.cateringRequest.deleteNotAllowed'), { variant: 'error' });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
      <DialogContent sx={{ p: 4, justifyContent: 'center' }}>
        <Typography variant={'h6'} color={'#E02020'} textAlign="center" mb={2}>
          <DangerIcon sx={{ color: 'transparent' }} />
          <br />
          <Translate contentKey="kafaatApp.business.home.deleteConfirmation">Delete Confirmation</Translate>
        </Typography>
        <Typography color={'secondary'} textAlign="center">
          <Translate contentKey="kafaatApp.cateringRequest.deleteConfirm">Delete Confirm</Translate>
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          mb: 2,
        }}
      >
        <Button size={'large'} color="primary" variant="contained" disabled={loading} onClick={confirmDelete}>
          <Translate contentKey="kafaatApp.business.home.delete">Delete</Translate>
        </Button>
        <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
          <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CateringRequestDeleteDialog;
